import React, {useEffect, Fragment, useState} from 'react';
import { Link } from 'react-router-dom';
import apiClient from "../../http-common";
import { useQuery } from "react-query";

const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
const getDate = (date) => new Date(date).toDateString();
const addressText = (address) => {
    let last6 = address.slice(address.length - 11);
    let first3 = address.substring(0,3);
    return first3+'XXX'+last6;
}
const AnalysisExternal = () => {

    const initialAnalysis = {
        todayInvestments: 0,
        todayWithdrawals: 0,
        todayReferrals: 0,
        Investments: [],        
        todayInvestmentsPercent: 0,
        todayWithdrawalsPercent: 0,
        todayReferralsPercent: 0,
        totalInvestments: 0,
        totalWithdrawals: 0,
        totalReferrals: 0
        
    }
    const [analysis, setAnalysis] = useState(initialAnalysis);
    const { isLoading: isLoadingAnalysis, refetch: getAnalysis } = useQuery(
        "account-analysis",
        async () => {
          return await apiClient.get("/api/analysis");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setAnalysis({...analysis, 
                    todayInvestments: res.data.today_investment,
                    todayWithdrawals: res.data.today_withdrawal,
                    todayReferrals: res.data.today_referral,
                    Investments: res.data.investments,                    
                    todayInvestmentsPercent: res.data.investment_percent,
                    todayWithdrawalsPercent: res.data.withraw_percent,
                    todayReferralsPercent: res.data.referral_percent,
                    totalInvestments: res.data.total_investments,
                    totalWithdrawals: res.data.total_withdrawals,
                    totalReferrals: res.data.total_referrals
                });
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
           if(window && window.init_template){
            window.init_template();
        }  
        getAnalysis();
      }, [isLoadingAnalysis]);
      const {todayInvestments,
         todayWithdrawals,
         todayReferrals,
         Investments,
         todayInvestmentsPercent,
         todayWithdrawalsPercent,
         todayReferralsPercent,
        totalInvestments,
        totalWithdrawals,
        totalReferrals
        } = analysis;

  return (
    <div className='mt-4'>
        <div className="content my-0 mt-n2 px-1">
            <div className="d-flex">
                <div className="align-self-center">
                    <h3 className="font-16 mb-2">Trading activities</h3>
                </div>
                <div className="align-self-center ms-auto">
                    <Link to="/" className="font-12 pt-1 text-info">
                        {isLoadingAnalysis ? <span>updating...</span>: <span>updated now</span>}
                    </Link>
                </div>
            </div>
        </div>


        <div className="container">
            <div className="card pt-2 px-0 rounded-2 shadow-sm">
                <div className="content mt-0 mb-0">
                {Investments.length > 0 &&
                Investments.map(Investment => (  
                <Fragment key={Investment.id}>          
                    {Investment.status === 'pending' && 
                    <Link to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-yellow shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined font-20 color-white" style={{fontSize: "28px", marginTop: "11px"}}>pending_actions</i>
                            </span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{addressText(Investment.user.email)}</h5>
                            <p className="mb-0 font-11 opacity-50">{getDate(Investment.created_at)}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-yellow-dark">{getAmount(Investment.amount)}</h4>
                            <p className="mb-0 font-12 color-yellow-dark">Status: {Investment.status}</p>
                        </div>
                    </Link>
                    }
                    {Investment.status === 'waiting approval' && 
                    <Link to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-yellow shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined font-20 color-white" style={{fontSize: "28px", marginTop: "11px"}}>pending_actions</i>
                            </span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{addressText(Investment.user.email)}</h5>
                            <p className="mb-0 font-11 opacity-50">{getDate(Investment.created_at)}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-yellow-dark">{getAmount(Investment.amount)}</h4>
                            <p className="mb-0 font-12 color-yellow-dark">Status: {Investment.status}</p>
                        </div>
                    </Link>
                    }
                    {Investment.status === 'approved' && 
                    <Link to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined font-20 color-white" style={{fontSize: "28px", marginTop: "11px"}}>task_alt</i>
                            </span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{addressText(Investment.user.email)}</h5>
                            <p className="mb-0 font-11 opacity-50">{getDate(Investment.created_at)}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-green-dark">{getAmount(Investment.amount)}</h4>
                            <p className="mb-0 font-12 color-green-dark">Status: {Investment.status}</p>
                        </div>
                    </Link>
                    }  
                    {Investment.status === 'completed' && 
                    <Link to="#" className="d-flex pb-3">
                        <div className="align-self-center">
                            <span className="icon rounded-s me-2 gradient-gray shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined font-20 color-white" style={{fontSize: "28px", marginTop: "11px"}}>done_all</i>
                            </span>
                        </div>
                        <div className="align-self-center ps-1">
                            <h5 className="pt-1 mb-n1">{addressText(Investment.user.email)}</h5>
                            <p className="mb-0 font-11 opacity-50">{getDate(Investment.created_at)}</p>
                        </div>
                        <div className="align-self-center ms-auto text-end">
                            <h4 className="pt-1 mb-n1 color-gray-dark">{getAmount(Investment.amount)}</h4>
                            <p className="mb-0 font-12 color-gray-dark">Status: {Investment.status}</p>
                        </div>
                    </Link>
                    } 
                </Fragment> 
                ))
                }                             
                </div>
            </div>
        </div>
       
    </div>
  )
}

export default AnalysisExternal